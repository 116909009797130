<template scoped>
  <div>
    <el-form-item
      :rules="rules.apiKey"
      label="Account Number"
      prop="sms.accessYou.accountNumber"
      :style="{ marginTop: '10px' }"
    >
      <el-input
        v-model="provider.sms.accessYou.accountNumber"
        placeholder=""
      ></el-input>
    </el-form-item>

    <el-form-item
      :rules="rules.senderAddress"
      label="Password"
      prop="sms.accessYou.password"
      :style="{ marginTop: '10px' }"
    >
      <el-input
        v-model="provider.sms.accessYou.password"
        placeholder=""
      ></el-input>
    </el-form-item>
  </div>
</template>
      
<script scoped>
export default {
  props: {
    provider: Object,
  },
  methods: {
    validator_accountNumber: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the account number should not be empty"));
      } else {
        callback();
      }
    },
    validator_password: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the password should not be empty"));
      } else {
        callback();
      }
    },
  },
  data: function () {
    return {
      rules: {
        accountNumber: [{ validator: this.validator_accountNumber, trigger: "blur" }],
        password: [
          { validator: this.validator_password, trigger: "blur" },
        ],
      },
    };
  },
};
</script>    